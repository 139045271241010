<template>

    <section id="contact">
        <div class="map-content">
            <div style="width: 100%"><iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13621.628006172716!2d74.2567376!3d31.4029107!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x54306f4dc09f4913!2sSlimLogix!5e0!3m2!1sen!2s!4v1618486465002!5m2!1sen!2s" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>

            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-md-8 mx-auto">
                    <div class="contact-content">
                        <h3>Contact Here</h3>
                        <p>Providing Scaleable and flexible customized AI & Machine-Learning solutions and Computer-Vision solutions based on latest techniques, technology and tools.</p>
                        <ul class="contact-info">
                            <li>
                                <div class="media">
                                    <div class="icon-area"><i class="icofont-phone"></i>
                                    </div>
                                    <div class="media-body contact-info-here">
                                        <h5><a href="#!">(+92) 3244996300</a></h5>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="media">
                                    <div class="icon-area"> <i class="icofont-location-pin"></i>
                                    </div>
                                    <div class="media-body contact-info-here">
                                        <h5>Valencia Town Camercial Market H Block Lahore, Punjab, Pakistan</h5>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="media">
                                    <div class="icon-area"> <i class="icofont-send-mail"></i>
                                    </div>
                                    <div class="media-body contact-info-here">
                                        <h5><a href="#!">info@slimlogix.com</a></h5>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>
    #contact{
        margin-top: 100px;
    }
</style>